@import "_fonts";
$golos: "Golos", sans-serif;
$font: "PT_Root", sans-serif;
$black: #2c2d2e;
$white: #fff;
$green: #46db39;
$green-2: #3ec232;
$red: #f04438;
$gray-25: #fcfcfd;
$gray-300: #d0d5dd;
$gray-400: #98a2b3;
$gray-600: #475467;
$gray-700: #344054;
$gray-800: #1d2939;
$gray-900: #101828;
$gray-950: #0c111d;

$screen-sm: 800px;
$screen-md: 1024px;
$screen-lg: 1440px;

@mixin size($height, $width: false) {
  height: #{$height}rem;
  @if $width {
    width: #{$width}rem;
  } @else {
    width: #{$height}rem;
  }
}
@mixin fnt($size: 16, $height: 24, $color: $black, $weight: 400, $font: $font) {
  font-family: $font;
  font-style: normal;
  font-variation-settings: "wght" $weight;
  font-weight: unset;
  font-size: #{$size}rem;
  line-height: #{$height}rem;
  color: $color;
}
