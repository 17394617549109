@font-face {
  font-family: 'Golos';
  src:
          // url('/public/fonts/Golos/Golos-Text_Regular.woff2') format('woff2'),
          url('./fonts/Golos/Golos-Text_Regular.woff2') format('woff2'),
          url('./fonts/Golos/Golos-Text_Regular.woff') format('woff');
  font-style: normal;
}
@font-face {
  font-family: 'Golos';
  src:
          url('./fonts/Golos/Golos-Text_DemiBold.woff2') format('woff2'),
          url('./fonts/Golos/Golos-Text_DemiBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Golos';
  src:
          url('./fonts/Golos/Golos-Text_DemiBold.woff2') format('woff2'),
          url('./fonts/Golos/Golos-Text_DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'PT_Root';
  src:
          url('./fonts/PT_Root/pt-root-ui_vf.woff2') format('woff2'),
          url('./fonts/PT_Root/pt-root-ui_vf.woff') format('woff'),
          url('./fonts/PT_Root/pt-root-ui_vf.ttf') format("truetype");
  font-style: normal;
}